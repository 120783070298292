<template>
  <div class="vi-header">
   <div id="subtabContainer">
        <input
          class="tab-radio"
          id="schOnline"
          name="main-group"
          type="radio"
          checked="checked"
        />
        <div class="web-tab-content">
          <div class="sub-tabs-container clearfix">
            <label for="priceFree"  :class="classIndex == 0 ? 'subbtn subactive' : 'subbtn'">Free</label>
            <label for="pricePaid" style="margin-left: 5px;" :class="classIndex == 1 ? 'subbtn subactive' : 'subbtn'" >Paid</label>
          </div>
          <input
            class="tab-radio"
            id="priceFree"
            @click="setIndex(0)"
            name="priceDet"
            type="radio"
            checked="checked"
          />
          <div class="sub-web-tab-content">
            <ul>
              <li>
                <div class="singleprice">
                  <div class="featCont pt0 ">
                    <div class="header headernew pb0 ">
                      <h2 class="pricehdr">Enroll Your School</h2>
                    </div>
                    <ul>
                      <!-- <li class="bullet">
                        <label
                          >Enroll your School in Vidhyaan.com Search
                          Portal</label
                        >
                      </li> -->
                       <li class="bullet">
                        <label>List your School</label>
                      </li>
                      <li class="bullet">
                        <label>Online Admission Process Setup</label>
                      </li>
                      <li class="bullet"><label>Track Your Admission/Enquiry's</label></li>
                      <li class="bullet">
                        <label>Login Portal for School Admin to track Admission</label>
                      </li>
                      <li class="bullet">
                        <label>School Overview Setup</label>
                      </li>
                       <!-- <li class="bullet">
                        <label>School Facilities</label>
                      </li>
                        <li class="bullet">
                        <label>Contact Information</label>
                      </li> -->
                     
                      <li class="bullet">
                        <label
                          >Setup Seat Availability for Admission Process</label
                        >
                      </li>
                      <li class="bullet">
                        <label>School Facilities</label>
                      </li>
                      <li class="bullet"><label>Contact Information</label></li>
                        <li class="bullet">
                        <label>View on Google Map</label>
                      </li>
                      <li class="bullet">
                        <label>Upload School Gallery Pics and Photos</label>
                      </li>
                      <li class="bullet">
                        <label>Ratings & Reviews by Parents</label>
                      </li>
                    </ul>
                    <button @click="redirectRoute('/register/school')"  class="priceregbtn">REGISTER FOR FREE</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <input @click="setIndex(1)"
            class="tab-radio"
            id="pricePaid"
            name="priceDet"
            type="radio"
          />
          <div class="sub-web-tab-content">
            <ul>
              <li>
                <div class="singleprice">
                  <div class="featCont pt0">
                    <div class="header headernew pb0">
                      <h2 class="pricehdr">School Essential</h2>
                    </div>
                    <ul>
                      <li class="bullet">
                        <span><em>Everything in Enroll +</em></span>
                      </li>
                      <li class="bullet">
                        ADMISSION MANAGEMENT
                        <ul class="subfeat">
                          <li class="bullet">
                            Manage Your Student registration by Academic Year
                          </li>
                          <li class="bullet">
                            Online Admission process with Payment Gateway
                            Integration
                          </li>
                          <li class="bullet">Online Certificate Request</li>
                          <!-- <li class="bullet">
                            Separate Login for School Admin & Teachers
                          </li>
                          <li class="bullet">SMS & Email Alerts</li> -->
                          <li class="bullet">
                            Manage Classes, Students & Teachers
                          </li>
                          <li class="bullet">Promote Your Students</li>
                          <li class="bullet">Alert & Notifications</li>
                          <!-- <li class="bullet">
                            Online Live Class Integration [Add-Ons]
                          </li>
                          <li class="bullet">School Annoucenments [Add-Ons]</li> -->
                        </ul>
                      </li>
                      <li class="bullet">
                        FEES MANAGEMENT
                        <ul class="subfeat">
                          <li class="bullet">Automated Recurring Fees</li>
                         <li class="bullet">One-time Adhoc Fees</li>
                         <li class="bullet">Online/Offline Payment Tracking</li>
                         <li class="bullet">Fee Receipts Via Email/PDF</li>
                         <li class="bullet">Payment GateWay [RazorPay]</li>
                         <li class="bullet">Sibling,Discount & penaities</li>
                         <li class="bullet">Record Partial Payments</li>
                        </ul>
                      </li>
                      <li class="bullet">
                        PARENT / STUDENT PORTAL
                        <ul class="subfeat">
                          <li class="bullet">
                            Student Profile Management
                          </li>
                          <li class="bullet">Fee Summary & Payment History</li>
                                                    <li class="bullet">Online TC Request Submission</li>

                        </ul>
                      </li>
                      <li class="bullet">
                        TECHNICAL SUPPORT
                        <ul class="subfeat">
                          <li class="bullet">Chat Support</li>
                          <li class="bullet">Case Management</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="pricebtnsection">
                    <p class="pricents">
                      Available in
                      <em
                        ><strong
                          >Quarterly, Half Yearly, Yearly Plans</strong
                        ></em
                      >
                    </p>
                    <button class="priceregbtn">CONTACT SALES</button>
                  </div>
                </div>
                <div class="singleprice">
                  <div class="featCont pt0">
                    <div class="header headernew pb0">
                      <h2 class="pricehdr">
                        School Premium
                        <span
                          style="font-size: 0.68rem; font-weight: normal;color: #0971d7;"
                          class="blink_me"
                          >Launching Soon</span
                        >
                      </h2>
                    </div>
                    <ul>
                      <li class="bullet">
                        <span><em>Everything in School Essential +</em></span>
                      </li>
                      <li class="bullet">
                        <label>Academic Management</label>
                      </li>
                      <ul>
                      <li class="bullet"><label>Exam Schedule,Result & Reports</label></li>
                      <li class="bullet"><label>Online Assessments</label></li>
                      <li class="bullet"><label>Curriculum Planner</label></li>
                      <li class="bullet"><label>Attendance Management [Staff Check-in/out]</label></li>
                      <li class="bullet"><label>Leave Management</label></li>
                      <li class="bullet"><label>TimeTable [Daily Class Schedule]</label></li>
                      <li class="bullet"><label>Facilities Management [Inventory Transport, Library, Hostel]</label></li>
                      <li class="bullet"><label>Dedicated Domain/link your exisiting Domain</label></li>
                      <li class="bullet"><label>Dashboard [Holiday listing,Birthdays,Attendance]</label></li>
                      <li class="bullet"><label>Dedicated Cloud Hosting Server + Database</label></li>

                      </ul>
                     
                      <li class="bullet">
                        TEACHER PORTAL
                        <ul class="subfeat">
                          <li class="bullet">Academic Management</li>
                            <ul>
                              <li class="bullet">Student Attendance </li>
                               <li class="bullet">Timetable,Exam Schedule & Result </li>
                                <li class="bullet">Cirriculam Tracking </li>
                                 <li class="bullet">Assignments Tracking </li>
                                  <li class="bullet">Leave Application </li>
                                   <li class="bullet">Automated Grading and Assessment </li>
                            </ul>
                        </ul>
                      </li>
                      <li class="bullet">
                        PARENT PORTAL
                        <ul class="subfeat">
                               <li class="bullet">Student Academic Performance </li>
                                <li class="bullet">Attendance Tracking </li>
                                <li class="bullet">Remedial Program Tracking </li>
                                <li class="bullet">Leave Application </li>
                               
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="pricebtnsection">
                    <p class="pricents">
                      Available in
                      <em
                        ><strong
                          >Quarterly, Half Yearly, Yearly Plans</strong
                        ></em
                      >
                    </p>
                    <button class="priceregbtn">CONTACT SALES</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../utils/secureUI";

export default {
  name: "vi-admission-home",
  
  data() {
    return {
      productList: [],
      planList: [],
      masterPlanList: [],
      currentActiveTab : 0,
      currentProductId: null,
      planTypes: [],
      currentActiveType: 0,
      tokenAvaiable: false,
      userDetail: {},
      classIndex: 0
    };
  },

  // created() {
  //   this.getProductDetials();
  //   this.initComponent();
  //   this.checkUserToken()
  // },

  methods: {
    setIndex(index){
      this.classIndex = index
    },
    // checkUserToken(){
    //  let userData = secureUI.sessionGet("user");

    //     if (userData) {
    //      this.tokenAvaiable = true
    //     }
            
    // },
    // initComponent() {
    //     let userData = secureUI.sessionGet("user");

    //     if (userData) {
    //       this.userDetail = userData.userDetails
    //     }
          
    // },
    // logout() {
    //   localStorage.removeItem('user');
    //   this.$router.push('/');
    // },,
    redirectRoute(vipath) {

       if (this.$route.path !== vipath) this.$router.push({ path: vipath })
    
    },
    // getSavePrice(data){
    //   if(data.length > 1){
    //     let value = data.filter( x => x.label == 'Save_1')
    //     if(value){
    //        return value[0].value_formatted
    //     }else{
    //       return 0
    //     }
    //   }else{
    //     return 0
    //   }

    // },
    // getOrginalPrice(data){
    //    if(data.length > 1){
    //     let value = data.filter( x => x.label == 'MRP_1')
    //     if(value){
    //        return value[0].value_formatted
    //     }else{
    //       return 0
    //     }
    //   }else{
    //     return 0
    //   }
    // },
    // getPlanDetails(index,productId){
    //   if(this.currentActiveTab != index){
    //    this.currentActiveTab = index
    //    this.currentProductId = productId
    //    this.getPlanList();
    //   }
    // },
    // filterByFrequency(index,type){

    //     this.planList = []
    //     this.currentActiveType = index
    //     this.planList = this.masterPlanList.filter( x => x.frequencyType == type)

    // },
    // getbillingName(interval,interval_unit){

    //  if(interval == 1 && interval_unit == 'months'){
    //    return 'every month'
    //  }else if(interval == 1 && interval_unit == 'years'){
    //    return 'annually'
    //  }else if(interval == 3 && interval_unit == 'months'){
    //    return 'every 3 Months'
    //  }else if(interval == 6 && interval_unit == 'months'){
    //    return 'every 6 Months'
    //  }else if(interval == 1 && interval_unit == 'weeks'){
    //    return 'every week'
    //  }

    // },
    // async goOnlinePayment(data){
    //   let userData = secureUI.sessionGet("user");

    //   if (!userData) {
    //     this.$router.push('/login');
    //   } else {

    //      if(data.recurring_price == 1 || data.recurring_price == 0 ){
    //      this.$router.push('/subscribe/enrollSchool');
    //      }else{

    //       let custName = userData.userDetails.firstName + " " + userData.userDetails.lastName

    //       let obj =  {
    //           customer: {
    //             display_name: custName,
    //             email: userData.userDetails.email,
    //             mobile: userData.userDetails.phoneNo,
    //             is_taxable : true,
    //             tax_id: "",
    //             tax_authority_id: "903000006345",
    //             tax_authority_name: "ATO",
    //             place_of_contact: "TN",
    //             gst_treatment: "consumer",
    //               custom_fields: [
    //                 {
    //                     "label": "AccountId",
    //                     "value": userData.userDetails.account._id
    //                 }
    //             ]
    //           },
    //           plan: {
    //             plan_code: data.plan_code,
    //             tax_id: null
    //           },
     
    //         }

    //         const response = await ViService.viPost("/common/createSubscriptionAndCustomer", obj);

    //         if (response.isSuccess) {
    //           window.open(response.data.hostedpage.url)
    //         } else {
    //             this.$toasted.error(response.message);
    //         }

    //      }
    //   }
    // },
    // async getProductDetials(){
       
    //    const response = await ViService.viGetOnly("/app/getAllProducts");

    //     if (response.isSuccess) {
    //       if(response.data.products.length > 0){
    //          const activeProducts = response.data.products.filter(x => x.status == 'active')
    //          if(activeProducts.length > 0){
    //             this.productList = activeProducts
    //             this.currentProductId = this.productList[0].product_id
    //             this.getPlanList();
    //          }
             
    //       }
         
    //     } else {
    //       this.$toasted.error(response.message);
    //     }

    // },
    // async getPlanList(){

    //   this.planList = [];
    //   this.planTypes = [];

    //    const response = await ViService.viGetOnly(`/app/getPlanList?productId=${this.currentProductId}`);
       
    //     if (response.isSuccess) {
         
          
    //       if(response.data.plans.length > 0 ){

    //         for(let x of response.data.plans){
    //           for(let y of x.custom_fields){
    //             if(y.placeholder == "cf_frequency_type"){
    //               x['frequencyType'] = y.value
    //             }
    //           }
    //         }
    //        this.planTypes =  response.data.plans.map(item => item.frequencyType).filter((value, index, self) => self.indexOf(value) === index)
    //        this.masterPlanList = response.data.plans
    //        this.planList = response.data.plans.filter( x => x.frequencyType == this.planTypes[0])
           
    //       }

    //     } else {
    //       this.$toasted.error(response.message);
    //     }

    // }
  },
};
  // var header = document.getElementById("maintab");
  //        var btns = header.getElementsByClassName("tabbtn");
  //        for (var i = 0; i < btns.length; i++) {
  //          btns[i].addEventListener("click", function() {
  //          var current = document.getElementsByClassName("tabactive");
  //          if (current.length > 0) { 
  //            current[0].className = current[0].className.replace(" tabactive", "");
  //          }
  //          this.className += " tabactive";
  //          });
  //        }
         
// var subheader = document.getElementById("subtabContainer");
// var subbtns = subheader.getElementsByClassName("subbtn");
// for (var i = 0; i < subbtns.length; i++) {
//   subbtns[i].addEventListener("click", function() {
//   var subcurrent = document.getElementsByClassName("subactive");
//   if (subcurrent.length > 0) { 
//     subcurrent[0].className = subcurrent[0].className.replace(" subactive", "");
//   }
//   this.className += " subactive";
//   });
// }

</script>

<style lang="scss" scoped>
.sub-tab-content li>div .header h2.pricehdr{color: #000!important;text-align: center;}
.pt0{padding-top: 0!important}
.pb0{padding-bottom: 0!important}

.pricents{background:#ff7f1d;text-align: center;padding:4px 6px;color: #fff!important;margin-top: 20px;}
.priceregbtn{background:#fff;border-radius: 4px;text-align:center;border: 1px solid #2954a2;padding: 4px 10px;width: 100%;margin-top: 15px;transition: 0.5s all;}
.priceregbtn:hover{color: #fff;border: 1px solid #2954a2;background-color: #2954a2;}

.sub-tab-content li>div .featCont li.bullet label{text-align: left;justify-content: left;font-family:"Proxima Nova Regular", sans-serif;display:inline;}
.sub-tab-content li>div .header, .sub-tab-content li>div .featCont{margin-bottom: auto;}
.pricebtnsection{margin-top: auto;padding: 0 1.5rem 1.5rem;}
.sub-tab-content li>div.singleprice{transition: 0.5s all;}
.sub-tab-content li>div.singleprice:hover{box-shadow: 0px 0px 6px #ccc;border: 1px solid #2954a2;}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>
