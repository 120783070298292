const fs = require("fs");
const fastcsv = require("fast-csv");

const convert = async (csvfile) => {

 
  let stream = fs.createReadStream(csvfile);
  let csvData = [];
  let csvStream = fastcsv
    .parse()
    .on("data", function(data) {
      csvData.push({
        id: data[0],
        name: data[1],
        description: data[2],
        createdAt: data[3],
      });
    })
    .on("end", function() {
      csvData.shift();
    });

  stream.pipe(csvStream);
};

export default convert;
